.event-details-page {
	overflow-x: hidden;

    .event-details-form {
		margin: 15px auto;
		width: 100%;

		@include breakpoint {
			width: 80%;
		}

		height: calc(100% - 105px);

        .MuiFormControl-root {
			z-index: 0;
			margin: 10px 0;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}
		}

		.form-control-full {
			width: 80%;
			@include breakpoint {
				width: 100%;
			}
		}

        .image-control {
			width: 100%;
			height: 170px;
			align-items: center;
			display: flex;
			margin: 20px 0 10px 0;
			position: relative;
			flex-direction: column;
		}

		.select {
			position: relative;
			margin-bottom: 10px;
			width: 80%;

			@include breakpoint {
				width: 100%;
			}
		}

		.under-select {
			display: flex;
			align-items: center;
			width: 100%;
			flex-wrap: wrap;
		}

		.MuiFormControl-root {
			margin-top: 10px;
		}

		.MuiFormGroup-row {
			width: 100%;
			justify-content: center;

			@include breakpoint {
				justify-content: space-between;
			}
		}

        .event-details-form-actions {
            display: flex;
            width: 80%;
            margin: 0 auto;
            padding: 10px 0;
    
            @include breakpoint {
                width: 100%;
            }
        }
    }
}
