.company-content {
	padding: 10px;
	height: calc(100% - 108px);

	.company-page-header {
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.MuiInputAdornment-root {
			display: none;
			@include breakpoint {
				display: inherit;
			}
		}

		.MuiButton-root {
			max-height: 56px;
		}
	}

	i {
		color: $color-info;
	}

	.uncheck {
		fill: $color-white;
	}

	.actions {
		max-width: fit-content !important;
	}

	[data-field="actions"] {
		max-width: fit-content !important;
		width: unset !important;
	}

	.info-cell {
		.MuiDataGrid-columnHeaderTitle:after {
			content: "*";
			color: $color-info;
		}
	}
}
.modal-company-preview {
	.company-details-container {
		height: 100%;
		margin: unset;
		width: 100%;
		position: relative;
	}
}
