.company-details-page {
	overflow-x: hidden;

	.company__deactivated {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		color: $color-warning;

		svg {
			margin-right: 5px;
			padding-bottom: 4px;
		}
	}

	.stepper {
		margin-top: 15px;
		z-index: 1;
		width: 88%;
		margin-left: auto;
		margin-right: auto;
		height: 60px;

		.MuiStepLabel-root {
			@include breakpoint {
				transform: scale(1.2);
			}
		}

		.MuiStepIcon-text {
			fill: $color-white;
		}
	}

	.internal-status-choice-container {
		margin-left: 30px;
	}

	.company-details-form {
		margin: 15px auto;
		width: 100%;

		@include breakpoint {
			width: 80%;
		}

		height: calc(100% - 105px);

		.company-details-form-stepper {
			h3 {
				width: 80%;
				padding: 15px 0;
				@include breakpoint {
					width: 100%;
				}
			}

			width: 100%;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: unset;
			@include breakpoint {
				min-height: 86%;
			}

			.company-details-form-stepper--intern-type {
				width: 80%;
				z-index: 4;

				@include breakpoint {
					width: 100%;
				}
			}
		}

		.MuiFormControl-root {
			z-index: 0;
			margin: 10px 0;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}
		}

		.form-control-full {
			width: 80%;
			@include breakpoint {
				width: 100%;
			}
		}

		.image-control {
			width: 100%;
			height: 170px;
			align-items: center;
			display: flex;
			margin: 20px 0 10px 0;
			position: relative;
			flex-direction: column;
		}

		.select {
			position: relative;
			margin-bottom: 10px;
			width: 80%;

			@include breakpoint {
				width: 100%;
			}
		}

		.under-select {
			display: flex;
			align-items: center;
			width: 100%;
			flex-wrap: wrap;
		}

		.MuiFormControl-root {
			margin-top: 10px;
		}

		.MuiFormGroup-row {
			width: 100%;
			justify-content: center;

			@include breakpoint {
				justify-content: space-between;
			}
		}

		.company-details-form__checkbox__group {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}

	.company-details-form-actions {
		display: flex;
		width: 80%;
		margin: 0 auto;
		padding: 10px 0;

		@include breakpoint {
			width: 100%;
		}
	}
}
