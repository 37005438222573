.forgot-password {
	background-color: $color-primary;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;

	img {
		width: 100%;
	}

	.forgot-password-form {
		@include breakpoint {
			width: 450px;
		}
		position: relative;
		max-width: 90%;
		background-color: $color-white;
		flex-direction: column;
		display: flex;
		border-radius: 10px;
		margin: auto;
		padding: 15px;
		border: 1px solid $color-secondary;

		h4 {
			color: $color-white;
			width: 100%;
			text-align: center;
			left: 0;
			top: -50px;
			position: absolute;
		}

		img {
			margin-bottom: 10px;
		}

		.MuiFormControl-root {
			margin: 10px 0;
		}

		.MuiButton-root {
			margin: 10px 0;
		}

		a {
			transition: all 0.5s ease-in-out;
			text-align: center;
			color: $color-primary;
			&:hover {
				color: $color-primary-light;
			}
		}

		.forgot-password-success {
			max-width: 450px;
			padding: 10px;
			margin: 10px;
			text-align: center;
		}
	}
}
