.home {
	position: absolute;
	top: 0; left: 0;
	right: 0; bottom: 0;
	background-color: $color-primary;
	width: 100%;
	height: 100%;

	.action-call-footer {
		position: relative;
		left: 0; right: 0; bottom: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;

		a {
			color: $color-secondary;
			margin: 5px auto;
		}
	}

	.home__logo {
		margin: 3% auto;
		border-radius: 10px;
		width: 300px;
		background-color: $color-white;
	}

	.home__action__container {
		display: flex;
		width: 100%;
		height: 90%;
		justify-content: center;

		.action-call-container {
			@include breakpoint {
				width: 350px;
				margin: 0 25px;
			}

			width: 45%;
			margin: 0 5px;
			border: 1px solid $color-secondary;

			height: 80%;
			justify-content: space-around;
			position: relative;
			background-color: $color-white;
			flex-direction: column;
			display: flex;
			border-radius: 10px;
			padding: 15px;
			text-align: center;

			img {
				width: 55%;
				margin: 0 auto;
			}
		}
	}

	.action-call-video {
		display: flex;
		flex-direction: column;
		width: 50%;
		height: 60%;
		margin: 0 auto 15px;
		border: 1px solid $color-secondary;
		background-color: $color-white;
		border-radius: 10px;
		padding: 15px;
		text-align: center;

		iframe {
			width: 90%;
			height: 85%;
			margin: 0 auto;
		}
	}
}