@import "./base.module";
@import "./tailwind";
@import "../../components/sidebar/sidebar";
@import "../../components/header/header";
@import "../../components/inputs/inputs";
@import "../../containers/app/app";
@import "../../containers/companyPage/companyPage";
@import "../../containers/offerPage/offerPage";
@import "../../containers/eventPage/eventPage";
@import "../../containers/admin/activityAdminPage/activityAdminPage";
@import "../../containers/admin/companyAdminPage/companyAdminPage";
@import "../../containers/admin/userAdminPage/userAdminPage";
@import "../../containers/admin/jobAdminPage/jobAdminPage";
@import "../../containers/companyDetailsPage/companyDetailsPage";
@import "../../containers/admin/eventAdminPage/eventAdminPage";
@import "../../containers/admin/eventAdminPage/eventAdminForm/eventAdminForm";
@import "../../containers/loginPage/loginPage";
@import "../../containers/profilePage/profilePage";
@import "../../containers/registrationPage/registrationPage";
@import "../../containers/homePage/homePage";
@import "../../components/footer/footer";
@import "../../containers/forbiddenPage/forbiddenPage";
@import "../../containers/forgotPasswordPage/forgotPasswordPage";
@import "../../containers/resetPasswordPage/resetPasswordPage";
@import "../../containers/admin/eventAdminPage/eventLocationModal/eventLocationModal";
@import "../../containers/admin/eventAdminPage/eventLocationModal/LocationFormModal/locationFormModal";

.MuiDataGrid-root {
	background-color: $color-white;
}

body {
	margin: 0;
	font-family: $font-family !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: $color-white scale;
	height: 100vh;
	overflow: hidden;

	background: $background-color;
	#root {
		height: 100vh;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	h1 {
		font-size: 33px;
		font-weight: 700;
		line-height: 1.2;
		color: $color-primary-dark;
	}

	h2 {
		font-size: 28px;
		font-weight: 700;
		line-height: 1.2;
		color: $color-primary-dark;
	}

	h3 {
		font-size: 23px;
		font-weight: 700;
		line-height: 1.2;
		color: $color-primary-dark;
	}

	h4 {
		font-size: 17px;
	}

	a {
		color: $color-primary;
		text-decoration: underline;

		&:hover {
			color: $color-primary-light;
		}
	}

	//remove datePicker styles
    input[type=date].hide-calendar::-moz-datepicker-appearance {
        display: none;
    }
	input[type=date].hide-calendar::-webkit-inner-spin-button {
		display: none;
	}
	input[type=date].hide-calendar::-webkit-calendar-picker-indicator {
		display: none;
	}

	.modal {
		@include breakpoint {
			width: 70%;
			height: 80%;
		}
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95%;
		height: 95%;
		background-color: $color-white;
		border-radius: 10px;
		padding: 15px;

		.modal__close {
			position: absolute;
			right: 20px;
			font-size: 27px;
			text-transform: lowercase;
			cursor: pointer;
			z-index: 1;
		}

		&:focus {
			outline: thin dotted;
		}
	}

	.title {
		text-align: center;
		padding: 10px;
		background-color: $color-primary;
		color: $color-white;
		font-size: 30px;
		border-radius: 5px;
	}

	.page {
		width: 100%;
		overflow-y: auto;
		height: 100%;
	}

	.content {
		max-width: 1400px;
		margin: 0 auto;
	}

	/* Change the white to any color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px $color-white inset !important;
	}

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: $color-primary;
		border-radius: 3px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: $color-secondary;
		border-radius: 3px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: $color-secondary-dark;
	}

	h4 {
		display: block;
		margin-top: 1.33em;
		margin-bottom: 1.33em;
		margin-left: 0;
		margin-right: 0;
		font-weight: bold;
	}

	.ReactCollapse--collapse {
		transition: height 500ms;
	}

	//SWAL CSS
	.swal2-confirm {
		background-color: $color-primary !important;
	}

	.swal2-cancel {
		background-color: $color-red !important;
	}

	.swal2-input {
		&:focus {
			border-color: $color-primary;
		}
	}

	.swal2-warning {
		border-color: $color-warning !important;
		color: $color-warning !important;
	}

	.swal2-container {
		z-index: 1500;
	}
	//END SWAL CSS

	//MUI CSS

	.MuiDataGrid-root {
		font-size: 16px;
	}

	.MuiInputBase-formControl {
		background-color: $color-white;
	}

	.MuiButton-root {
		background-color: $color-white;
		border: 1px solid $color-primary-light;
		&:disabled {
			border-color: rgba(0, 0, 0, 0.26);
		}
	}

	.MuiButton-textSecondary {
		border: 1px solid $color-secondary !important;
	}

	//END MUI CSS
}
