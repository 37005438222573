.profile {
	display: flex;
	align-items: center;
	flex-direction: column;

	img {
		width: 200px;
	}

	.user__deactivated {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		color: $color-warning;

		svg {
			margin-right: 5px;
			padding-bottom: 4px;
		}
	}

	.profile-form {
		width : 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0 5px;

		@include breakpoint {
			justify-content: space-between;
			width: 80%;
		}
		.MuiButton-root {
			margin: 10px auto;
			width: 80%;

			@include breakpoint {
				width: 100%;
			}
		}

		.file-control {
			z-index: 0;
			margin-top: 10px;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}
		}

		img {
			margin-bottom: 10px;
		}

		.MuiFormControl-root {
			z-index: 0;
			margin: 10px 0;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}

			.MuiFormControl-root {
				z-index: 0;
				width: 100% !important;
				margin: unset;
			}
		}

		.select-form-control {
			z-index: 3;
			margin: 10px auto;
			width: 80%;
			@include breakpoint {
				width: 100%;
			}
		}

		.select-form-control--half-first {
			z-index: 2;
			margin: 10px 0;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}
		}

		.select-form-control--half-second {
			z-index: 1;
			margin: 10px 0;
			width: 80%;
			@include breakpoint {
				width: 48%;
			}
		}
	}
}
