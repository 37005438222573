.app {
	height: calc(100% - $footer-height);
}

.app-container {
	display: flex;
	height: calc(100% - $header-height);
}

.overlay {
	position: fixed; /* Positioning and size */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 1301;

	.spin {
		display: inline-block;
		width: 30px;
		height: 30px;
		border: 3px solid $color-white;
		border-radius: 50%;
		border-top-color: $color-primary;
		animation: spin 1s ease-in-out infinite;
		-webkit-animation: spin 1s ease-in-out infinite;
	}

	@keyframes spin {
		to {
			-webkit-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		to {
			-webkit-transform: rotate(360deg);
		}
	}

	.overlay-spin {
		z-index: 999;
		position: absolute;
		top: 50%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		width: 71px;
		height: 71px;
		border: 6px solid $color-white;
		border-top-color: $color-primary;
		border-right-color: $color-primary;
	}
}
