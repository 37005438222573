.login {
	background-color: $color-primary;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;

	img {
		min-width: 300px;
		margin:0 auto;

	}

	.login-forgot-password {
		font-size: 14px;
		text-align: right;
		margin: 0 10px;
	}

	.login-form {
		@include breakpoint {
			min-width: 450px;
		}
		position: relative;

		background-color: $color-white;
		flex-direction: column;
		display: flex;
		border-radius: 10px;
		margin: auto;
		padding: 10px;
		border: 1px solid $color-secondary;

		h4 {
			color: $color-white;
			width: 100%;
			text-align: center;
			left: 0;
			top: -50px;
			position: absolute;
		}

		img {
			margin-bottom: 10px;
		}

		.MuiFormControl-root {
			margin: 10px;
		}

		.MuiButton-root {
			margin: 10px;
		}

		.login-form-registration {
			font-size: 14px;
			text-align: center;
		}
	}
}
