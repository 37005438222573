.job-page-header {
	height: 70px;
	display: flex;
	justify-content: center;

	align-items: center;

	justify-content: space-between;
	width: 100%;

	.MuiInputAdornment-root {
		display: none;
		@include breakpoint {
			display: inherit;
		}
	}

	.MuiButton-root {
		max-height: 56px;
	}
}

.job-content {
	padding: 10px;
	height: calc(100% - 108px);

	i {
		color: $color-info;
	}

	.info-cell {
		.MuiDataGrid-columnHeaderTitle:after {
			content: "*";
			color: $color-info;
		}
	}
}
