.footer {
	background: $color-primary;
	color: $color-white;
	height: $footer-height;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
	font-size: 14px;
	@include breakpoint {
		font-size: 16px;
	}

	p {
		text-align: center;
	}
	a {
		color: $color-secondary;
	}
}

.help-button {
    position: fixed;
    bottom: 1.05rem;
    right: 1.05rem;
    cursor: pointer;
    z-index: 9;
}
