@import "breakpoint-sass";

//SCSS variables
$font-family: "Roboto";
$color-primary: theme("colors.primary");
$color-primary-light: theme("colors.primaryLight");
$color-primary-dark: theme("colors.primaryDark");
$color-secondary: theme("colors.secondary");
$color-gray: theme("colors.gray");
$color-secondary-dark: theme("colors.secondaryDark");
$color-secondary-light: theme("colors.secondaryLight");
$color-white: theme("colors.white");
$color-black: theme("colors.black");
$color-red: theme("colors.red");
$color-warning: theme("colors.warning");
$color-info: theme("colors.info");
$background-color: theme("colors.background");

$header-height: 75px;
$footer-height: 45px;
$box-shadow: 1px 0 4px 0 rgb(0 0 0 / 25%);

@mixin breakpoint($point: tablet) {
	@if $point == desktop {
		@media only screen and (min-width: theme("screens.desktop")) {
			@content;
		}
	} @else if $point == laptop {
		@media only screen and (min-width: theme("screens.laptop")) {
			@content;
		}
	} @else if $point == mobile {
		@media only screen and (min-width: theme("screens.mobile")) {
			@content;
		}
	} @else {
		@media only screen and (min-width: theme("screens.tablet")) {
			@content;
		}
	}
}

:export {
	colorPrimary: $color-primary;
	colorSecondary: $color-secondary;
	colorRed: $color-red;
}
