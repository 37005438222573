$offer-page-header-height-desktop: 75px;
$offer-page-header-height-mobile: 135px;

.offer-page {
	overflow-y: unset !important;

	.offer-page-header {
		@include breakpoint {
			height: $offer-page-header-height-desktop;
		}
		max-width: 1400px;
		height: $offer-page-header-height-mobile;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 auto;
    }

	.offer-container {
		@include breakpoint {
			max-height: calc(100% - ($offer-page-header-height-desktop));
		}

		max-height: calc(100% - ($offer-page-header-height-mobile));
		display: flex;
		position: relative;

		.offer-list-content {
			margin-top: 5px;
			width: 100%;
			flex-flow: row wrap;
			display: flex;
			justify-content: center;
			overflow-y: auto;
			transition: all 0.4s linear;
			padding: 0 10px;
			overflow-x: hidden;

			.offer-tile,
			.offer-tile {
				position: relative;
				cursor: pointer;
				width: 90%;
				@include breakpoint {
					width: 46%;
				}

				@include breakpoint(laptop) {
					width: 29%;
				}
				border: 1px solid $color-gray;
				height: 280px;
				margin: 10px;

				min-width: 300px;
				border-radius: 10px;
				background-color: $color-white;

				display: flex;
				align-items: center;
				flex-direction: column;

				color: unset;
				text-decoration: none;
				text-align: center;

				&:hover {
					transition: 0.4s ease all;
					box-shadow: $box-shadow;
				}

				.offer-tile__clock {
					position: absolute;

					right: 5px;
					top: 5px;
					border-radius: 50%;
					background-color: inherit;

					svg,
					img {
						fill: $color-secondary;
					}
				}

				.offer-tile__image {
					margin: auto;
					max-width: 100%;
					max-height: 120px;

					img {
						fill: $color-primary;
						object-fit: contain;
						height: 140px;
					}
				}

				.offer-tile__postal {
					width: 100%;
					background-color: $color-primary;
					color: $color-white;
					font-weight: bolder;
					text-transform: uppercase;
					padding-top: 8px;
					padding-bottom: 8px;
					border-radius: 0 0 10px 10px;
				}
			}

			.offer-tile--selected {
				border: 1.5px solid $color-primary;

				&:hover {
					box-shadow: none !important;
				}
			}
		}
	}
}

.offer-details-container {
	transition: width 0.4s linear;

	background-color: $color-white;
	width: 100%;
	position: absolute;
	margin: unset;
	height: 100%;

	@include breakpoint {
		position: unset;
		width: 50%;
		height: unset;
		margin: 10px;
	}

	.offer-details-container-view {
		border: 1px solid $color-gray;
		width: 100%;
		height: 100%;
		position: relative;

		border-radius: 10px;

		display: flex;
		flex-direction: column;

		header {
			padding: 10px;
			display: flex;
			width: 100%;
			flex-direction: column;
			box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
			border-bottom: 2px solid rgba(0, 0, 0, 0.08);
			overflow-y: auto;
			min-height: 40%;
			max-height: 60%;

			@include breakpoint(mobile) {
				overflow-y: unset;
				min-height: unset;
				max-height: unset;
			}
		}

		.offer-details-container-view-content {
			padding: 10px;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			width: 100%;
			word-break: break-word;

			svg {
				fill: $color-secondary;
			}

			iframe {
				width: 100%;
				height: 1200px;
			}
		}

		.offer-details-container--close {
			position: absolute;
			right: 8px;
			font-size: 27px;
			text-transform: lowercase;
			cursor: pointer;
			z-index: 1;
		}
	}
}
