.modal-file-preview {
	width: fit-content !important;
	overflow: hidden;
	display: none;
	min-width: 400px;

	@include breakpoint {
		display: unset;
	}

	.react-pdf__Document {
		width: fit-content;
		height: calc(100% - 30px);
		overflow: auto;
	}

	.pdf-tools {
		width: 100%;
		background: $color-primary;
		color: $color-white;
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		height: 30px;
		align-items: center;

		p {
			font-size: 16px;
			margin-left: auto;
			margin-right: 15px;
		}

		a {
			margin-right: 15px;
			color: inherit;
			padding-top: 3px;
		}

		.MuiButton-root {
			border: unset;
			color: $color-info;
			font-size: 25px;

			&:disabled {
				color: $color-gray;
			}

			&:hover {
				color: $color-white;
			}
		}
	}
}

.image-preview-upload {
	width: 200px;
	height: 150px;
	cursor: pointer;
	border-radius: 10px;
	border: 1px solid lightgray;
	background-color: $color-white;
	position: relative;

	.MuiInputLabel-root {
		position: absolute;
		top: 0;
		left: 13px;
		top: -11px;
		background-color: $background-color;
	}

	&:focus {
		border: 1px solid $color-primary;
	}

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.87);
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		cursor: pointer;
	}

	svg {
		width: 100%;
		height: 100%;
		object-fit: contain;
		cursor: pointer;
		fill: $color-primary;
	}
}

.file-upload {
	text-align: center;
	background-color: $color-white;
	border: 0.125rem solid $color-primary;
	border-radius: 4px;
	background-repeat: repeat-x;
	cursor: pointer;
	height: 44px;
	color: $color-primary;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	b {
		text-overflow: ellipsis;
		overflow: hidden;

		@include breakpoint {
			margin-left: auto;
		}
	}

	span {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	svg {
		font-size: 25px;
	}

	.file-upload__preview {
		z-index: 1;
		color: rgb(0, 114, 229);
		margin-left: auto;
		padding-right: 8px;
		display: none;

		&:hover {
			transform: scale(1.1);
			transition: 0.5s ease all;
		}

		@include breakpoint {
			display: unset;
		}
	}
}

// CSS REACT SELECT
.react-select__control {
	max-height: 68px;
	overflow-y: auto;
}

// END CSS REACT SELECT