.sidebar {
	@include breakpoint {
		display: none;
	}
	.sidebar-header {
		height: $header-height;
		border-bottom: 0.0625rem solid $color-gray;
	}

	padding: unset;
	box-shadow: $box-shadow;
	background-color: $color-white;
	transition: all 0.6s linear;
	z-index: 996;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 0;
	margin: 0;

	.active {
		color: $color-primary !important;

		svg {
			fill: $color-primary !important;
		}
	}

	.link {
		overflow: hidden;
		padding: 9px 5px;
		display: flex;
		cursor: pointer;
		border-radius: 30px;
		color: unset;
		text-decoration: none;
		transition: all 0.6s linear;
		font-size: 20px;
		font-weight: bold !important;
		width: 100%;

		svg {
			font-size: 24px;
			margin-right: 5px;
			margin-left: 5px;
			margin-top: 2px;
		}
	}
	.link:hover {
		background-color: $color-white;
		color: $color-secondary;
		transition: color 100ms ease-out;

		svg {
			fill: $color-secondary;
		}
	}

	.link-collapse {
		transition: all 0.6s linear;
		font-size: 20px;
		font-weight: bold !important;
		width: 100%;
		padding: 9px 5px;
		display: flex;
		flex-direction: column;
		margin-bottom: 3px;
		overflow: hidden;

		&:hover {
			background-color: unset;
			color: unset;
		}

		svg {
			transition: 0.2s ease all;
			font-size: 24px;
			margin-right: 5px;
			margin-left: 5px;
			margin-top: 2px;
		}

		width: 100%;

		.ReactCollapse--content {
			display: flex;
			flex-direction: column;
			margin-left: 15px;

			font-size: 17px;

			a {
				color: unset;
				text-decoration: unset;
				height: 35px;
				text-overflow: ellipsis;
				width: 220px;
				display: flex;
				align-items: center;

				p {
					height: 100%;
					border-left: 3px solid $color-primary;
					margin-right: 17px;
				}

				&:hover {
					background-color: $color-white;
					color: $color-secondary;
					transition: color 100ms ease-out;
				}
			}
		}
	}

	.link > h2 {
		font-weight: 700;
		font-size: 20px;
		margin-right: 20px;
	}
}

.sidebar--closed {
	min-width: 0px;
	width: 0px;

	.link {
		width: 0%;
	}

	.link-collapse {
		width: 0%;
	}
}

.sidebar--active {
	min-width: 220px;
	width: 220px;
}
